import React, { useEffect, useState } from 'react';
import dateFormat from "dateformat";
import { Link } from 'react-router-dom';
const SingleItem = ({ data, index,  handleReject }) => {
    const [userData, setuserData] = useState([]);
    useEffect(() => {
        if(data?.user_id){
            fetch(`https://api.prospectstreetx.com/api/admin/user/view/single/${data?.user_id}`, {
                method: 'GET',
            })
                .then((res) => res.json())
                .then((data) => {
                    setuserData(data.data)
                })
        }
     
    }, [])
    return (
        <>
            <tr>
                <td data-label="Full Name">
                    <Link to={`/admin/users/details/${userData?._id}`}>
                        <span className="fw-bold">{userData?.fname} {userData?.lname}</span>
                    </Link>
                    <br />

                </td>
                <td data-label="Email">
                    {data?.amount}$
                </td>

                <td data-label="Phone">
                    {data?.reason}
                </td>


                <td data-label="Joined At">
                    {dateFormat(data.Created_At, "d-m-yyyy h:MM:ss TT")}
                </td>

                <td data-label="Action">
                    <div className="button--group">
                        {data?.status === 0 ?
                         <button onClick={() => handleReject(data._id)} className="btn btn-sm btn-outline-danger ms-1 confirmationBtn"
                         >
                             <i className="la la-eye-slash"></i>Reject
                         </button>
                          :''}
                       

                        <Link to={`/admin/aproved/loan/${data._id}`} className="btn btn-sm btn-outline-success ms-1 confirmationBtn"
                        >
                            <i className="la la-eye"></i> Details
                        </Link>
                    </div>
                </td>

            </tr>

        </>
    );
};

export default SingleItem;