import React, { useContext, useRef, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
const NewCoinAdd = () => {
  const { authUser } = useContext(AuthContext);
  const refSubmitDis = useRef(false);
  const [userImage, setUserImage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);

  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current = true;
    setErrorMessage("");
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    const userData = { ...InpuData, image: userImage };
    if (userData.image === "") {
      toast.error(`please choose coin image`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      refSubmitDis.current = false;
    } else {
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .post(
          `https://api.prospectstreetx.com/api/admin/new/coin/store`,
          userData,
          config
        )
        .then((response) => {
          event.target.reset();
          toast.success(`${response?.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          refSubmitDis.current = false;
        }).catch((error) => {
          refSubmitDis.current = false;
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  if (!authUser?.payment_gateways) {
    if (authUser?.payment_gateways === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    return (
      <>
        <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
          <h6 className="page-title">Deposit Gateway</h6>
        </div>

        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-4">
              <form onSubmit={handleSubmitData}>
                <div className="card-body">
                  <div className="payment-method-item">
                    <div className="payment-method-body">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>Coin Image</label>
                            <div className="image-upload">
                              <div className="thumb">
                                <div className="avatar-edit">
                                  <input
                                    type="file"
                                    onChange={handleImage}
                                    className="profilePicUpload"
                                    id="profilePicUpload0"
                                    accept=".jpg, .png, .jpeg"
                                  />
                                  <label
                                    htmlFor="profilePicUpload0"
                                    className="bg--primary"
                                  >
                                    Image
                                  </label>
                                  <small class="mt-2  ">
                                    Supported files: <b>jpeg, jpg, png</b>. |
                                    Will be resized to:
                                    <b>800x800</b>
                                    px.
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className=" col-md-8 ">
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="heading" className="required">
                                Coin Name
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="name"
                                id="heading"
                              />
                              {errorMessage?.name && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.name?.msg}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <label htmlFor="heading" className="required">
                                Coin Symbol
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                name="symbol"
                                id="heading"
                              />
                              {errorMessage?.symbol && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.symbol?.msg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card border--primary mt-3">
                            <h5 className="card-header bg--primary">Range</h5>
                            <div className="card-body">
                              <div className="form-group">
                                <label htmlFor="min_limit" className="required">
                                  Lower limit
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="lower_limit"
                                    id="min_limit"
                                  />
                                </div>
                                {errorMessage?.lower_limit && (
                                  <span className="text-danger pt-2">
                                    {errorMessage?.lower_limit?.msg}
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label htmlFor="max_limit" className="required">
                                  Higher limit
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    id="max_limit"
                                    name="higher_limit"
                                  />
                                </div>

                                {errorMessage?.higher_limit && (
                                  <span className="text-danger pt-2">
                                    {errorMessage?.higher_limit?.msg}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <div className="card border-primary mt-3">
                            <h5 className="card-header bg--primary">Default</h5>
                            <div className="card-body">
                              <div className="form-group">
                                <label
                                  htmlFor="percent_charge"
                                  className="required"
                                >
                                  Default Price
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="default_price"
                                    id="percent_charge"
                                    placeholder="Percent Charge"
                                  />
                                </div>

                                {errorMessage?.default_price && (
                                  <span className="text-danger pt-2">
                                    {errorMessage?.default_price?.msg}
                                  </span>
                                )}
                              </div>
                              <div className="form-group">
                                <label
                                  htmlFor="coin_stocks"
                                  className="required"
                                >
                                  Coin Stocks
                                </label>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    step="any"
                                    className="form-control"
                                    name="coin_stocks"
                                    id="coin_stocks"
                                    placeholder="Percent Charge"
                                  />
                                </div>
                                {errorMessage?.coin_stocks && (
                                  <span className="text-danger pt-2">
                                    {errorMessage?.coin_stocks?.msg}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <button
                    type="submit"
                    disabled={refSubmitDis?.current}
                    className="btn btn-primary w-100 h-45"
                  >
                    Submit{refSubmitDis.current && "..."}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
};
export default NewCoinAdd;
