import React, { useEffect, useState, useRef, useContext } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { RotatingSquare } from "react-loader-spinner";
import { AuthContext } from "../../../Contexts/AuthContext/AuthProvider";
const NewCoinEdit = () => {
  const { authUser } = useContext(AuthContext);
  const { id } = useParams();
  const [data, setData] = useState({});
  const [results, setResults] = useState({});

  const refSubmitDis = useRef(false);

  const [userImage, setUserImage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);
  const handleImage = (e) => {
    setUserImage(e.target.files[0]);
  };

  const handleSubmitData = (event) => {
    event.preventDefault();
    refSubmitDis.current = true;
    setErrorMessage("");
    const formData = new FormData(event.currentTarget);
    const InpuData = Object.fromEntries(formData);
    if (!userImage) {
      const userData = { ...InpuData };
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .put(
          `https://api.prospectstreetx.com/api/admin/new/coin/update/${id}`,
          userData,
          config
        )
        .then((response) => {
          event.target.reset();
          toast.success(`${response?.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setResults(response?.data);
          refSubmitDis.current = false;
        }).catch((error) => {
          refSubmitDis.current = false;
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    } else {
      const userData = { ...InpuData, image: userImage };
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      axios
        .put(
          `https://api.prospectstreetx.com/api/admin/new/coin/update/${id}`,
          userData,
          config
        )
        .then((response) => {
          event.target.reset();
          toast.success(`${response?.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setResults(response?.data);
          refSubmitDis.current = false;
        }).catch((error) => {
          refSubmitDis.current = false;
          if (error?.response?.data?.errors) {
            setErrorMessage(error?.response?.data?.errors);
          } else {
            setErrorMessage(error?.response?.data);
          }
        });
    }
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`https://api.prospectstreetx.com/api/admin/new/coin/view/${id}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((data) => {
        setData(data.data);
        setLoading(false);
      });
  }, [results]);

  if (!authUser?.manage_section) {
    if (authUser?.manage_section === undefined) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <h1>unauthorized</h1>
        </>
      );
    }
  } else {
    if (loading) {
      return (
        <>
          <div className="loader-css">
            <RotatingSquare
              visible={true}
              height="100"
              width="100"
              color="#4fa94d"
              ariaLabel="rotating-square-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex mb-30 flex-wrap gap-3 justify-content-between align-items-center">
            <h6 className="page-title">New Coin Update</h6>
            <div className="d-flex flex-wrap justify-content-end gap-2 align-items-center breadcrumb-plugins"></div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 mb-30">
              <div className="card">
                <div className="card-body">
                  <form onSubmit={handleSubmitData}>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>Image</label>
                          <div className="image-upload">
                            <div className="thumb">
                              <div className="avatar-preview">
                                <div
                                  className="profilePicPreview"
                                  style={{ height: "120px", width: "100%" }}
                                >
                                  <img
                                    src={`https://api.prospectstreetx.com/${data?.image}`}
                                    style={{ width: "100%", height: "120px" }}
                                    alt=""
                                  />
                                </div>
                              </div>
                              <div className="avatar-edit">
                                <input
                                  type="file"
                                  onChange={handleImage}
                                  className="profilePicUpload"
                                  id="profilePicUpload0"
                                  accept=".jpg,.jpeg,.png"
                                />
                                <label
                                  htmlFor="profilePicUpload0"
                                  className="bg--primary"
                                >
                                  Choose Image
                                </label>
                                <small class="mt-2  ">
                                  Supported files: <b>jpeg, jpg, png.</b> Image
                                  will be resized into (width: 1200px * height:
                                  800px)
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-8 ">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="heading" className="required">
                              Coin Name
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              id="heading"
                              defaultValue={data?.name}
                            />
                            {errorMessage?.name && (
                              <span className="text-danger pt-2">
                                {errorMessage?.name?.msg}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="heading" className="required">
                              Coin Symbol
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="symbol"
                              id="heading"
                              defaultValue={data?.symbol}
                            />
                            {errorMessage?.symbol && (
                              <span className="text-danger pt-2">
                                {errorMessage?.symbol?.msg}
                              </span>
                            )}
                            {errorMessage && (
                              <span className="text-danger pt-2">
                                {errorMessage?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pb-5">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card border--primary mt-3">
                          <h5 className="card-header bg--primary">Range</h5>
                          <div className="card-body">
                            <div className="form-group">
                              <label htmlFor="min_limit" className="required">
                                Lower limit
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="lower_limit"
                                  id="min_limit"
                                  defaultValue={data?.lower_limit}
                                />
                              </div>
                              {errorMessage?.lower_limit && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.lower_limit?.msg}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="max_limit" className="required">
                                Higher limit
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  id="max_limit"
                                  name="higher_limit"
                                  defaultValue={data?.higher_limit}
                                />
                              </div>

                              {errorMessage?.higher_limit && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.higher_limit?.msg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <div className="card border-primary mt-3">
                          <h5 className="card-header bg--primary">Default</h5>
                          <div className="card-body">
                            <div className="form-group">
                              <label
                                htmlFor="percent_charge"
                                className="required"
                              >
                                Default Price
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="default_price"
                                  id="percent_charge"
                                  placeholder="Percent Charge"
                                  defaultValue={data?.default_price}
                                />
                              </div>

                              {errorMessage?.default_price && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.default_price?.msg}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label htmlFor="coin_stocks" className="required">
                                Coin Stocks
                              </label>
                              <div className="input-group">
                                <input
                                  type="number"
                                  step="any"
                                  className="form-control"
                                  name="coin_stocks"
                                  id="coin_stocks"
                                  placeholder="Percent Charge"
                                  defaultValue={data?.coin_stocks}
                                />
                              </div>
                              {errorMessage?.coin_stocks && (
                                <span className="text-danger pt-2">
                                  {errorMessage?.coin_stocks?.msg}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <button
                        disabled={refSubmitDis?.current}
                        type="submit"
                        className="btn btn-primary w-100 h-45"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
};

export default NewCoinEdit;
